import { Correlation } from '@/Models'
import http from '@/services'
import { Component, Vue, Prop } from 'vue-property-decorator'
import * as toast from '@/services/toast'

@Component
export default class CorrelationComponent extends Vue {
  @Prop({ required: true })
  matrix_id!: number
  @Prop({ required: true })
  quadrant_content_principal_id!: number
  @Prop({ required: true })
  quadrant_content_right_id!: number
  @Prop({ required: false })
  correlation_state!: Correlation

  correlation_in_state: Correlation | null = new Correlation()
  correlation: string | null = null

  mounted() {
    this.correlation_in_state = this.correlation_state
    this.correlation = this.correlation_state
      ? this.correlation_state.correlation
      : null
    if (this.correlation && this.correlation == 'na') this.correlation = null
  }

  get show_correlation(): boolean {
    return this.correlation != null
  }

  async change_correlation() {
    //para cambiar los colores de las correlaciones solo se s
    let correlation_temp
    try {
      switch (this.correlation) {
        case 'blue':
          correlation_temp = 'green'
          break
        case 'yellow':
          correlation_temp = 'red'
          break
        case 'green':
          correlation_temp = 'yellow'
          break
        case 'na':
          correlation_temp = 'blue'
          break
        case null:
          correlation_temp = 'blue'
          break
        default:
          correlation_temp = null
      }
      if (this.correlation_in_state == null) {
        this.correlation_in_state = new Correlation()
        if (correlation_temp == null)
          this.correlation_in_state.correlation = 'na'
        else this.correlation_in_state.correlation = correlation_temp
        this.correlation_in_state.matrix_id = this.matrix_id
        this.correlation_in_state.quadrant_content_principal_id =
          this.quadrant_content_principal_id
        this.correlation_in_state.quadrant_content_right_id =
          this.quadrant_content_right_id
        const res = await http.post(
          'hoshin/quadrant_correlation/',
          this.correlation_in_state,
        )
        this.correlation_in_state = res.data
      } else {
        if (correlation_temp == null)
          this.correlation_in_state.correlation = 'na'
        else this.correlation_in_state.correlation = correlation_temp
        const res = await http.put(
          `hoshin/quadrant_correlation/${this.correlation_in_state.id}/`,
          this.correlation_in_state,
        )
        this.correlation_in_state = res.data != '' ? res.data : null
      }
      this.correlation = correlation_temp
    } catch (error) {
      toast.error(this.$i18n.t('toast.generic_error').toString())
      console.error(error)
    }
  }
}
